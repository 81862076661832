<template>
	<div>
		<h2>{{ $t('monte.stock_semence.title') }}</h2>
		<div class="box">
			<CustomTable
				id_table="semence_tiers"
				ref="table"
				:items="stock"
				:busy.sync="table_busy"
				primaryKey="uid"
		        :hrefsRoutes="config_table_hrefs"
				:externSlotColumns="['commentaire']"
			>
				<template v-slot:[`custom-slot-cell(commentaire)`]="{ data }">
					<span v-for="com in data.commentaire" :key="com">
						{{ com }}<br>
					</span>
				</template>
			</CustomTable>
		</div>

        <ModalInventaire
        	ref="inventaire"
        	:tiers_id="tiers_id"
        	:tier_stallions="tier_stallions"
        	@done="init_component"
        />

        <ModalRetour
        	ref="retour"
        	:tiers_id="tiers_id"
        	:tier_stallions="tier_stallions"
        	@done="init_component"
        />

        <ModalHistoryStock
        	ref="history"
        	@done="init_component"
        />
	</div>
</template>

<script type="text/javascript">
import StockSemence from '@/mixins/StockSemence'
import Navigation from '@/mixins/Navigation'

	export default {
		name: 'StockTiers',
		mixins: [StockSemence, Navigation],
		props: ['tiers_id'],
		data () {
			return {
				table_busy: false,
		        stock: [],
		        config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					},
				},
				events_tab: {
					'TableAction::goToInventaireStock': this.openInventaireModal,
					'TableAction::goToRetourStocks': this.openRetourStockModal,
					'TableAction::goToHistoryStock': this.openHistoryStock
				},
				tier_stallions: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.$refs.table) {
					this.$refs.table.refreshTable()
				}
				this.table_busy = true
				this.stock = await this.loadAllStockByTier(this.tiers_id)
				this.table_busy = false

				this.tier_stallions = []

				this.tier_stallions = this.stock.map(s => {
					return {
						horse: s.horse,
						stock: s.stock,
						date: s.last_inventory_date
					}
				})
			},

			openInventaireModal() {
				this.$refs.inventaire.openModal()
			},

			openRetourStockModal() {
				this.$refs.retour.openModal()
			},

			openHistoryStock(stock) {
				this.$refs.history.openModal(this.tiers_id, stock.horse.horse_id)
			}
		},

		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			ModalInventaire: () => import('@/components/Monte/ModalInventaire'),
			ModalRetour: () => import('@/components/Monte/ModalRetourStock'),
			ModalHistoryStock: () => import('@/components/Monte/ModalHistoryStock')
		}
	};
</script>